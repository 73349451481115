@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=greek");
.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  align-items: center;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "andreas";
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.chatContent {
  padding: 0 8px;
  margin-top: 10.2vh;
}

.writeMessageContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: absolute;
  width: 50%;
  overflow: auto;
  z-index: 100;
  bottom: 0;
  padding: 0 8px;
  background-color: #FFFFFF;
  justify-content: flex-start;
  align-items: center;
  height: auto;
}

.chatTextInput {
  width: 90%;
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  resize: none;
  line-height: 18px;
  overflow: auto;
  height: auto;
  padding: 4px 8px;
  margin: 8px 0;
}
.chatTextInput::placeholder {
  color: gainsboro;
}
.chatTextInput:focus {
  outline: none;
}

.chatTextInputFocused {
  border: 2px solid #008ECB;
}

.chatContentContainer {
  margin-bottom: 10vh;
}

/*# sourceMappingURL=chatScreen.css.map */
