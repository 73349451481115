.chatBtn{
    margin: 0 0 10px 0;
}
.chatBtn button{
    background-color: #83d0ab !important;
    color: white !important;
    padding: 1.5vh !important;
    margin: 0px auto;
    display: block;
    transition: all .3s ease-in-out;
}
.chatBtn button.enabled {
    background-color: #f17c7c !important;
}
.chatBtn button:disabled{
    background-color: #aac0b5 !important;
    cursor: not-allowed;
    pointer-events: all !important;
}