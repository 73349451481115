.infoBlockAgent{
    border-right: 1px solid #fff;
    display: flex;
    flex-grow: 1;
}
.infoBlockAgent .MuiGrid-container{
    align-items: center;
}
.infoBlockAgent .text{
   text-transform: uppercase;
}
.infoBlockAgent .number{
    text-align: center;
}
.infoBlockAgent.colored .number{
    color: #83d0ab;
}

.infoBlockAgent .text > div, .infoBlockAgent .number > div {
    font-size: .9rem;
}
@media screen and (max-width: 1604px){
    .infoBlockAgent .text > div, .infoBlockAgent .number > div {
        font-size: .8vw;
    }
}