.mainGridAgent{
    position: relative;
}
.mainGridAgent .mainGridContainer{
    top:50px !important;
    /*overflow-x: hidden;*/
    bottom: 48px !important;
}
.mainGridAgent .MuiGrid-container{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 10%;
}
.mainGridAgent .leftContent{
    background-color: #ffffff;
    height: 100%;
}
.mainGridAgent .centerContent{
    background-color: #f9f9f9;
    height: 100%;
    overflow-y: scroll;
}
.mainGridAgent .rightContent{
    background-color: #ffffff;
    height: 100%;
    position:relative;
    overflow-y: scroll;
    overflow-x: hidden;
}
