.agentStats .bottomBlock .MuiGrid-container {
    position: unset;
}
.bottomBlock{
    /*padding: 1vh 0;*/
    /*border-top: 1px solid #999;*/
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
}