.chatNotes .sectionTitle {
    color: #555;
    font-size: 14px;
}

.chatNotes .MuiInputBase-root {
    margin: 5px 0;
}
.chatNotes .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #6197e9 !important;
}
.chatNotes .MuiOutlinedInput-multiline{
    padding: unset;
}
.chatNotes textarea, .chatNotes input{
    font-style:italic;
    font-weight:600;
    font-size:12px;
    padding: 10px 12px;
}
.chatNotes textarea::placeholder, .chatNotes input::placeholder{
    opacity: 1;
    color:rgba(0, 0, 0, 0.7)
}
.chatNotes .detailsTitle {
    font-weight: 600;
    font-size: 13px;
    color: #555;
}
.chatNotes .details {
    font-size: 12px;
    color: #999;
    line-height:1.5
}
.chatNotes .newNote .saveButton{
    display: flex;
}

.chatNotes .newNote .success{
    background-color: #83d0ab;
}
.chatNotes .newNote .error{
    background-color: #f17c7c;
}
.chatNotes .newNote #client-snackbar{
    display: flex;
    align-items: center;
}
.chatNotes .newNote #client-snackbar svg{
    margin-right: 10px;
    font-size: 22px;
}