.agent-login-page {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 5000;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.agent-login-page form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    width: 20vw;
    background: #dadada;
    padding: 50px;
    border-radius: 7px;
}
.agent-login-page .spinner{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    width: 5vw !important;
    height: 5vw !important;
    padding: 50px;
    border-radius: 7px;
}
.agent-login-page .errorMessageForm{
    color: red;
    margin: 5px 0;
}

.agent-login-page .avatar {
    width: 60px;
    height: 60px;
    background-color: #999999;
}

.agent-login-page .avatar svg {
    font-size: 1.9em;
}

.agent-login-page .submit{
    background-color: #2c82cd;
}
.agent-login-page .submit:focus, .agent-login-page .submit:hover{
    background-color: #256dad;
}

.agent-login-page .MuiOutlinedInput-notchedOutline{
    background: white;
}
.agent-login-page .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #999999;
    border-width: 2px;
}

.agent-login-page .input-login .MuiFilledInput-root {
    background-color: white;
}
.agent-login-page .input-login label.MuiFormLabel-root.Mui-focused {
    color: #777;
}
.agent-login-page .input-login .MuiFilledInput-underline:after {
    border-bottom:2px solid #777;
}
