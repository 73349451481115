.chatTime{
    display: flex;
    flex-direction: row;
    padding: 1vh 0;
    /* margin: auto 0 !impo */
}
.chatTimeElapsed{
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
}
.chatTimeElapsed svg, .chatTime .chatTimeText{
    margin: auto 0;
    padding: 0 5px;
}