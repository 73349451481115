.statsTitle{
    text-transform: uppercase;
    font-weight: 600 !important;
    color:#999999;
    padding: 10px 0;
}
.coloredValue{
    color: #6197e9;
    font-weight: 600 !important;
}
.rate{
    text-align: center;
}
.rate svg{
    font-size: 2.2rem;
}
.rate span{
    display: block;
}

/*chat,wait colored percentage*/
.rate.chat.increase, .rate.wait.decrease{
    color: #83d0ab;
}
.rate.chat.decrease, .rate.wait.increase{
    color: #f17c7c;
}
