.statistics .MuiGrid-container {
    position: relative;
    align-items: center;
}
.statistics .title{
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding:10px 0 5vh 0;
    color:#999999;
}
.statistics .secondRow{
    margin-top: 8%;
}
.statistics canvas {
    max-height: 250px;
}

.statistics .spinner{
    margin-top: 25vh;
}