@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=greek);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* universal styling of scrollbar */
::-webkit-scrollbar{
  height:10px;
  width:10px;
  border-radius: 4px;
  background: transparent;
  transition: all 0.3s ease;
}
::-webkit-scrollbar:hover{
  /*background: #c6c7ca;*/
}
::-webkit-scrollbar-thumb{
  background:#777779;
  border-radius: 4px;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.agent-login-page {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 5000;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.agent-login-page form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    width: 20vw;
    background: #dadada;
    padding: 50px;
    border-radius: 7px;
}
.agent-login-page .spinner{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
    width: 5vw !important;
    height: 5vw !important;
    padding: 50px;
    border-radius: 7px;
}
.agent-login-page .errorMessageForm{
    color: red;
    margin: 5px 0;
}

.agent-login-page .avatar {
    width: 60px;
    height: 60px;
    background-color: #999999;
}

.agent-login-page .avatar svg {
    font-size: 1.9em;
}

.agent-login-page .submit{
    background-color: #2c82cd;
}
.agent-login-page .submit:focus, .agent-login-page .submit:hover{
    background-color: #256dad;
}

.agent-login-page .MuiOutlinedInput-notchedOutline{
    background: white;
}
.agent-login-page .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #999999;
    border-width: 2px;
}

.agent-login-page .input-login .MuiFilledInput-root {
    background-color: white;
}
.agent-login-page .input-login label.MuiFormLabel-root.Mui-focused {
    color: #777;
}
.agent-login-page .input-login .MuiFilledInput-underline:after {
    border-bottom:2px solid #777;
}

.mainGridAgent{
    position: relative;
}
.mainGridAgent .mainGridContainer{
    top:50px !important;
    /*overflow-x: hidden;*/
    bottom: 48px !important;
}
.mainGridAgent .MuiGrid-container{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 10%;
}
.mainGridAgent .leftContent{
    background-color: #ffffff;
    height: 100%;
}
.mainGridAgent .centerContent{
    background-color: #f9f9f9;
    height: 100%;
    overflow-y: scroll;
}
.mainGridAgent .rightContent{
    background-color: #ffffff;
    height: 100%;
    position:relative;
    overflow-y: scroll;
    overflow-x: hidden;
}

.tabsContent {
    position: relative;
    height: 100%;
    overflow-x: hidden;
}
.bottomTabs{
    top: auto !important;
    position: fixed !important;
    bottom: 0;
    width: 100% !important;
    box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.2) !important;
}
.bottomTabs .MuiTab-textColorInherit, .bottomTabs .statistics, .bottomTabs .statistics:hover {
    background: #ccc;
    color:#555;
    font-size: 1.2em;
    transition: all .3s ease-in-out;
}
.bottomTabs .statistics {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 48px;
    opacity: .7;
}
.bottomTabs .statistics.opened{
    background-color: #f17c7c;
    color: #fff;
}
.bottomTabs .MuiTab-textColorInherit.Mui-selected {
    background: transparent;
    color: #6197e9;
}

.bottomTabs .MuiTabs-indicator {
    background-color: #6197e9;
    height: 4px;
}
.tabsContent .chatSummaryNav:last-child{
    /*margin-bottom: 48px;*/
}
.tabsContent .chatSummaryNav:first-child {
    margin-top: 10px;
}
.chatSummaryNav.clicked .messageSummary{
    color:#6197e9;
}

.chatSummaryNav.unread .messageSummary{
    color: #E5AD4D;
}

.centeredContent{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}


/*.NavigationItem {*/
/*    margin: 10px 0;*/
/*    box-sizing: border-box;*/
/*    display: block;*/
/*    width: 100%;*/
/*}*/
.white{
    color: #fff
}
.white:hover, .white:focus, .white.active{
    color: #cccccc;
    transition: all .3s ease-in-out;
}
a{
    text-decoration: none;
}

/*.NavigationItem a{*/
/*    color: #8f5c2c;*/
/*    text-decoration: none;*/
/*    width: 100%;*/
/*    box-sizing: border-box;*/
/*    display: block;*/
/*}*/

/*.NavigationItem a:hover,*/
/*.NavigationItem a:active,*/
/*.NavigationItem a.active {*/
/*    color:#40a4c8;*/
/*}*/

/*@media (min-width: 500px){*/
/*    .NavigationItem {*/
/*        margin: 0;*/
/*        display: flex;*/
/*        height: 100%;*/
/*        width: auto;*/
/*        align-items: center;*/

/*    }*/

/*    .NavigationItem a{*/
/*        color: white;*/
/*        height: 100%;*/
/*        padding: 16px 10px;*/
/*        border-bottom: 4px solid transparent;*/
/*    }*/

/*    .NavigationItem a:hover,*/
/*    .NavigationItem a:active,*/
/*    .NavigationItem a.active {*/
/*        background-color: #8f5c2c;*/
/*        border-bottom: 4px solid #40a4c8;*/
/*        color:white;*/
/*    }*/
/*}*/
.messageSummary {
    padding: 20px 10px;
    border-bottom: 1px solid #eee;
    color: #333;
    background: #fcfcfc;
}
.messageSummary .MuiGrid-container {
    position: relative;
    align-items: center;
}

.messageSummary .userName {
    font-weight: 600;
    line-height: 1.2em;
}
.messageSummary .messageDate{
    font-weight: 700;
    font-size: 15px;
}

.messageSummary:hover {
    background-color:#f3f9fc;
}
.messageSummary .chatBtn {
    margin: 0;
}
.messageSummary .chatBtn button{
    padding:1vh !important;
}

@media screen and (max-width: 1500px){
    .messageSummary .userName{
        font-size: 15px;
    }
    .messageSummary .messageDate{
        font-size: 14px;
    }
}
.avatarInitials {
    width: 2vw !important;
    height: 2vw !important;
    font-size: 1.4rem !important;
    padding: 7px;
}

.avatarInitials svg {
    font-size: 1.7em;
}
.feeling{
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: column;*/
}
.feeling img{
    width: 1em;
    font-size: 1.1rem;
}
.feelingSection .withIconText img{
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

@media screen and (max-width: 1680px) {
    .feeling{
        flex-direction: column;
    }
}
@media screen and (max-width: 1400px) {
    .feelingLabel{
        display: none;
    }
}
.chatBtn{
    margin: 0 0 10px 0;
}
.chatBtn button{
    background-color: #83d0ab !important;
    color: white !important;
    padding: 1.5vh !important;
    margin: 0px auto;
    display: block;
    transition: all .3s ease-in-out;
}
.chatBtn button.enabled {
    background-color: #f17c7c !important;
}
.chatBtn button:disabled{
    background-color: #aac0b5 !important;
    cursor: not-allowed;
    pointer-events: all !important;
}
.headerAgent{
    flex-direction: row !important;
    justify-content: space-between;
    max-height: 80px;
}

.infoBlockAgent{
    border-right: 1px solid #fff;
    display: flex;
    flex-grow: 1;
}
.infoBlockAgent .MuiGrid-container{
    align-items: center;
}
.infoBlockAgent .text{
   text-transform: uppercase;
}
.infoBlockAgent .number{
    text-align: center;
}
.infoBlockAgent.colored .number{
    color: #83d0ab;
}

.infoBlockAgent .text > div, .infoBlockAgent .number > div {
    font-size: .9rem;
}
@media screen and (max-width: 1604px){
    .infoBlockAgent .text > div, .infoBlockAgent .number > div {
        font-size: .8vw;
    }
}
@media screen and (max-width:1500px){
    .statusAgent .MuiTypography-root{
        font-size: 1vw !important;
    }
    .statusAgent .custom-text .MuiTypography-root{
        font-size: 1.1vw !important;
    }
}
.toggleBtn {
    text-transform: uppercase;
    max-width: 65px;
}
.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  align-items: center;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "andreas";
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.chatContent {
  padding: 0 8px;
  margin-top: 10.2vh;
}

.writeMessageContainer {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  position: absolute;
  width: 50%;
  overflow: auto;
  z-index: 100;
  bottom: 0;
  padding: 0 8px;
  background-color: #FFFFFF;
  justify-content: flex-start;
  align-items: center;
  height: auto;
}

.chatTextInput {
  width: 90%;
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  resize: none;
  line-height: 18px;
  overflow: auto;
  height: auto;
  padding: 4px 8px;
  margin: 8px 0;
}
.chatTextInput::-webkit-input-placeholder {
  color: gainsboro;
}
.chatTextInput::-moz-placeholder {
  color: gainsboro;
}
.chatTextInput:-ms-input-placeholder {
  color: gainsboro;
}
.chatTextInput::-ms-input-placeholder {
  color: gainsboro;
}
.chatTextInput::placeholder {
  color: gainsboro;
}
.chatTextInput:focus {
  outline: none;
}

.chatTextInputFocused {
  border: 2px solid #008ECB;
}

.chatContentContainer {
  margin-bottom: 10vh;
}

/*# sourceMappingURL=chatScreen.css.map */

.bold {
  font-weight: bold;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.center {
  text-align: center;
  align-self: center;
  align-items: center;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

/*
  we only use this font for the numbers so we must append this property to all @font-face rules
  unicode-range: U+30-39;
 */
@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextThin-Regular_0.otf") format("opentype");
  font-weight: 100;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinTextLight-Regular_0.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "PF Din Text", sans-serif;
  src: url("%PUBLIC_URL%/fonts/PFDinText-Regular_0.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: 'andreas';
  src: url("%PUBLIC_URL%/fonts/PFDinTextBlack-Regular_0.otf") format("opentype");
  font-weight: 700;
}

.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.deliveryStatus {
  font-size: small;
  color: #007aff;
  margin-left: 5px;
}

.withError {
  align-items: center;
}

.waitingCircle {
  border-radius: 50%;
  border: 1px solid #007aff;
  width: 10px;
  height: 10px;
}

.chatTitle {
  display: flex;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  justify-content: center;
}

.warning {
  top:0;
  padding: 10px;
  text-align: center;
  background-color: indianred;
  position: absolute;
  width: 50%;
  color: #FFFFFF;
}

.statistics .MuiGrid-container {
    position: relative;
    align-items: center;
}
.statistics .title{
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding:10px 0 5vh 0;
    color:#999999;
}
.statistics .secondRow{
    margin-top: 8%;
}
.statistics canvas {
    max-height: 250px;
}

.statistics .spinner{
    margin-top: 25vh;
}
.sectionTitle{
    font-weight: 600 !important;
    padding:10px 0 5px 0;
    color:#999999;
}
.sectionTitle.centered{
    text-align: center;
}
.sectionTitle.capitalized{
    text-transform: uppercase !important;
}
.statsTitle{
    text-transform: uppercase;
    font-weight: 600 !important;
    color:#999999;
    padding: 10px 0;
}
.coloredValue{
    color: #6197e9;
    font-weight: 600 !important;
}
.rate{
    text-align: center;
}
.rate svg{
    font-size: 2.2rem;
}
.rate span{
    display: block;
}

/*chat,wait colored percentage*/
.rate.chat.increase, .rate.wait.decrease{
    color: #83d0ab;
}
.rate.chat.decrease, .rate.wait.increase{
    color: #f17c7c;
}

.chatSummaryEnded{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: 40vw !important;
}
@media screen and (min-width: 1400px){
    .chatSummaryEnded{
        max-width: 34vw !important;
    }
}
.endFeeling{
    text-align: center;
    margin: 10px 0;
}
.endFeeling .endFeelingTypo{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5px;
}
.endFeeling img{
    width: 2em;

}

.chatNotes .sectionTitle {
    color: #555;
    font-size: 14px;
}

.chatNotes .MuiInputBase-root {
    margin: 5px 0;
}
.chatNotes .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #6197e9 !important;
}
.chatNotes .MuiOutlinedInput-multiline{
    padding: unset;
}
.chatNotes textarea, .chatNotes input{
    font-style:italic;
    font-weight:600;
    font-size:12px;
    padding: 10px 12px;
}
.chatNotes textarea::-webkit-input-placeholder, .chatNotes input::-webkit-input-placeholder{
    opacity: 1;
    color:rgba(0, 0, 0, 0.7)
}
.chatNotes textarea::-moz-placeholder, .chatNotes input::-moz-placeholder{
    opacity: 1;
    color:rgba(0, 0, 0, 0.7)
}
.chatNotes textarea:-ms-input-placeholder, .chatNotes input:-ms-input-placeholder{
    opacity: 1;
    color:rgba(0, 0, 0, 0.7)
}
.chatNotes textarea::-ms-input-placeholder, .chatNotes input::-ms-input-placeholder{
    opacity: 1;
    color:rgba(0, 0, 0, 0.7)
}
.chatNotes textarea::placeholder, .chatNotes input::placeholder{
    opacity: 1;
    color:rgba(0, 0, 0, 0.7)
}
.chatNotes .detailsTitle {
    font-weight: 600;
    font-size: 13px;
    color: #555;
}
.chatNotes .details {
    font-size: 12px;
    color: #999;
    line-height:1.5
}
.chatNotes .newNote .saveButton{
    display: flex;
}

.chatNotes .newNote .success{
    background-color: #83d0ab;
}
.chatNotes .newNote .error{
    background-color: #f17c7c;
}
.chatNotes .newNote #client-snackbar{
    display: flex;
    align-items: center;
}
.chatNotes .newNote #client-snackbar svg{
    margin-right: 10px;
    font-size: 22px;
}
.historyNotesSection{
    /*TODO when notes don't exist background should be #fff */
    min-height: 4.5vh;
    max-height: 10vh;
    overflow-y: scroll;
    padding: 10px;
    background: #fafafa;
    margin: 5px 0;
    border-radius: 5px;
}
.historyNotes .titleHistoryNotes{
    color: #6197e9;
    font-weight: 500;
}
.historyNotes .titleHistoryNotes:hover{
    cursor: pointer;
    color: #007aff;
}

.drawerNotes .MuiDrawer-paperAnchorRight{
    width:25%;
}
.drawerNotes .drawerHeader{
    /*background-color: #404040; !*#404040*!*/
}
.drawerNotes .drawerHeader .titleDrawer{
    color: #6197e9;
}

.note{
    /*padding: 20px 10px;*/
    color: #333;
}
.note .noteHeader{
    background-color: #6197e9;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    padding: 15px 10px;
    color:#fff;
}
.note .noteHeader .titleTimestamp{
    text-align: right;
}
.note .noteContent{
    padding: 15px 20px;
    /*font-style: italic;*/
}
.printChats{
    border-top: 1px solid #999;
    margin-top: 10px;
}
.printChats .sectionTitle{
    color: #555;
    font-size: 14px !important;
}
.printChats .react-datepicker-wrapper input {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    background: #f9f9f9;
    color: #888;
    padding: 4px;
    border-radius: 5px;
    max-width: 6em;
    border: 1px solid #ccc;
}
.printChats button.Mui-disabled {
    cursor: not-allowed;
    pointer-events: auto;
}
.printOptions{
    padding: 0 32px;
}
.optionsCheckbox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.printChatOptionsTitle{
    color:#555;
    font-size: 14px !important;
}
.modal-email {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-email div[role='document'] {
    padding: 2em;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    min-width: 30vw;
    min-height: 200px;
}

.modal-email div.sendEmail-loading, .modal-email div.sendEmail-success, .modal-email div.sendEmail-failure {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.agentStats .infoBlockAgent{
    padding: 2px 0;
    border-bottom: 1px solid #999;
}
.agentStats .infoBlockAgent .text{
    color: #555555;
    text-transform: none;
    text-transform: initial;
    font-size: 1.3em;
    font-weight: 600;
}
.agentStats .chatInfo{
    padding-top: 10px;
    font-style: italic;
}
.agentStats .infoBlockAgent .MuiGrid-container, .agentStats .yourStats .MuiGrid-container {
    position: unset;
}

.agentStats .yourStats {
    padding: 1vh 0 5vh 0;
    border-top: 1px solid #999;
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
}

.yourStats .title{
    text-align: left;
}
.agentStats .bottomBlock .MuiGrid-container {
    position: unset;
}
.bottomBlock{
    /*padding: 1vh 0;*/
    /*border-top: 1px solid #999;*/
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
}
.activeChatClose {
    position: absolute;
    right: 0;
}
.chatDetails .MuiGrid-container {
    position: relative;
    align-items: center;
}
.chatDetails .avatarMessage {
    width: 2.5vw;
    height: 2.5vw;
}

.chatDetails .avatarMessage svg {
    font-size: 1.7em;
}

.chatDetails .userName {
    font-weight: 600;
    color: #555;
}
.chatDetails .activeTime{
    color: #aaa;
}
.chatDetails .basicInfo{
    padding: 0 0 10px 0;
    border-bottom: 1px solid #999;
}
.chatDetails .detailedInfo .sectionTitle{
    color: #555;
    font-size: 14px;
}
.chatDetails .detailedInfo{
    border-bottom: 1px solid #999;
    padding-bottom: 2px;
}
.chatDetails .detailsGrid{
    color: #aaa;
    padding: 0 0 5px 0;
}

.chatTime{
    display: flex;
    flex-direction: row;
    padding: 1vh 0;
    /* margin: auto 0 !impo */
}
.chatTimeElapsed{
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
}
.chatTimeElapsed svg, .chatTime .chatTimeText{
    margin: auto 0;
    padding: 0 5px;
}

.demo-editor {
    height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 400px !important;
}
