.note{
    /*padding: 20px 10px;*/
    color: #333;
}
.note .noteHeader{
    background-color: #6197e9;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    padding: 15px 10px;
    color:#fff;
}
.note .noteHeader .titleTimestamp{
    text-align: right;
}
.note .noteContent{
    padding: 15px 20px;
    /*font-style: italic;*/
}