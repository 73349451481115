.chatSummaryNav.clicked .messageSummary{
    color:#6197e9;
}

.chatSummaryNav.unread .messageSummary{
    color: #E5AD4D;
}

.centeredContent{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

