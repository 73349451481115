.agentStats .infoBlockAgent{
    padding: 2px 0;
    border-bottom: 1px solid #999;
}
.agentStats .infoBlockAgent .text{
    color: #555555;
    text-transform: initial;
    font-size: 1.3em;
    font-weight: 600;
}
.agentStats .chatInfo{
    padding-top: 10px;
    font-style: italic;
}
.agentStats .infoBlockAgent .MuiGrid-container, .agentStats .yourStats .MuiGrid-container {
    position: unset;
}

.agentStats .yourStats {
    padding: 1vh 0 5vh 0;
    border-top: 1px solid #999;
    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
}

.yourStats .title{
    text-align: left;
}