body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* universal styling of scrollbar */
::-webkit-scrollbar{
  height:10px;
  width:10px;
  border-radius: 4px;
  background: transparent;
  transition: all 0.3s ease;
}
::-webkit-scrollbar:hover{
  /*background: #c6c7ca;*/
}
::-webkit-scrollbar-thumb{
  background:#777779;
  border-radius: 4px;
}