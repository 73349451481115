.chatSummaryEnded{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: 40vw !important;
}
@media screen and (min-width: 1400px){
    .chatSummaryEnded{
        max-width: 34vw !important;
    }
}