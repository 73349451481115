.tabsContent {
    position: relative;
    height: 100%;
    overflow-x: hidden;
}
.bottomTabs{
    top: auto !important;
    position: fixed !important;
    bottom: 0;
    width: 100% !important;
    box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.2) !important;
}
.bottomTabs .MuiTab-textColorInherit, .bottomTabs .statistics, .bottomTabs .statistics:hover {
    background: #ccc;
    color:#555;
    font-size: 1.2em;
    transition: all .3s ease-in-out;
}
.bottomTabs .statistics {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 48px;
    opacity: .7;
}
.bottomTabs .statistics.opened{
    background-color: #f17c7c;
    color: #fff;
}
.bottomTabs .MuiTab-textColorInherit.Mui-selected {
    background: transparent;
    color: #6197e9;
}

.bottomTabs .MuiTabs-indicator {
    background-color: #6197e9;
    height: 4px;
}
.tabsContent .chatSummaryNav:last-child{
    /*margin-bottom: 48px;*/
}
.tabsContent .chatSummaryNav:first-child {
    margin-top: 10px;
}