.chatDetails .MuiGrid-container {
    position: relative;
    align-items: center;
}
.chatDetails .avatarMessage {
    width: 2.5vw;
    height: 2.5vw;
}

.chatDetails .avatarMessage svg {
    font-size: 1.7em;
}

.chatDetails .userName {
    font-weight: 600;
    color: #555;
}
.chatDetails .activeTime{
    color: #aaa;
}
.chatDetails .basicInfo{
    padding: 0 0 10px 0;
    border-bottom: 1px solid #999;
}
.chatDetails .detailedInfo .sectionTitle{
    color: #555;
    font-size: 14px;
}
.chatDetails .detailedInfo{
    border-bottom: 1px solid #999;
    padding-bottom: 2px;
}
.chatDetails .detailsGrid{
    color: #aaa;
    padding: 0 0 5px 0;
}
