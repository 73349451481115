.modal-email {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-email div[role='document'] {
    padding: 2em;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    min-width: 30vw;
    min-height: 200px;
}

.modal-email div.sendEmail-loading, .modal-email div.sendEmail-success, .modal-email div.sendEmail-failure {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}