.printChats .react-datepicker-wrapper input {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
    background: #f9f9f9;
    color: #888;
    padding: 4px;
    border-radius: 5px;
    max-width: 6em;
    border: 1px solid #ccc;
}
.printChats button.Mui-disabled {
    cursor: not-allowed;
    pointer-events: auto;
}
.printOptions{
    padding: 0 32px;
}
.optionsCheckbox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.printChatOptionsTitle{
    color:#555;
    font-size: 14px !important;
}