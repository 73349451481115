.feeling{
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: column;*/
}
.feeling img{
    width: 1em;
    font-size: 1.1rem;
}
.feelingSection .withIconText img{
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

@media screen and (max-width: 1680px) {
    .feeling{
        flex-direction: column;
    }
}
@media screen and (max-width: 1400px) {
    .feelingLabel{
        display: none;
    }
}