.endFeeling{
    text-align: center;
    margin: 10px 0;
}
.endFeeling .endFeelingTypo{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5px;
}
.endFeeling img{
    width: 2em;

}
