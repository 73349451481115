.historyNotesSection{
    /*TODO when notes don't exist background should be #fff */
    min-height: 4.5vh;
    max-height: 10vh;
    overflow-y: scroll;
    padding: 10px;
    background: #fafafa;
    margin: 5px 0;
    border-radius: 5px;
}
.historyNotes .titleHistoryNotes{
    color: #6197e9;
    font-weight: 500;
}
.historyNotes .titleHistoryNotes:hover{
    cursor: pointer;
    color: #007aff;
}

.drawerNotes .MuiDrawer-paperAnchorRight{
    width:25%;
}
.drawerNotes .drawerHeader{
    /*background-color: #404040; !*#404040*!*/
}
.drawerNotes .drawerHeader .titleDrawer{
    color: #6197e9;
}
