.messageSummary {
    padding: 20px 10px;
    border-bottom: 1px solid #eee;
    color: #333;
    background: #fcfcfc;
}
.messageSummary .MuiGrid-container {
    position: relative;
    align-items: center;
}

.messageSummary .userName {
    font-weight: 600;
    line-height: 1.2em;
}
.messageSummary .messageDate{
    font-weight: 700;
    font-size: 15px;
}

.messageSummary:hover {
    background-color:#f3f9fc;
}
.messageSummary .chatBtn {
    margin: 0;
}
.messageSummary .chatBtn button{
    padding:1vh !important;
}

@media screen and (max-width: 1500px){
    .messageSummary .userName{
        font-size: 15px;
    }
    .messageSummary .messageDate{
        font-size: 14px;
    }
}