.sectionTitle{
    font-weight: 600 !important;
    padding:10px 0 5px 0;
    color:#999999;
}
.sectionTitle.centered{
    text-align: center;
}
.sectionTitle.capitalized{
    text-transform: uppercase !important;
}